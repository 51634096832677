import { Button, Flex } from '@chakra-ui/react';
import type { ComponentType, ReactNode } from 'react';

import { AlertOctagon, AlertTriangle, CheckCircle, Info } from 'react-feather';

import { Stack } from '../stack';
import { Text } from '../text';
import { useChakraTheme } from '../useChakraTheme';

type Action = {
  action: () => void;
  label: string;
  tone?: keyof typeof toneToColorScheme;
  loading?: boolean;
};
export type NoticeProps = {
  actions?: {
    primary?: Action;
    secondary?: Action;
  };
  children?: ReactNode;
  size?: keyof typeof sizeToPadding;
  tone?: keyof typeof toneToIcon;
  title?: string;
  /**
   * Replace the default icon.
   *
   * The default icons are appropriate for the "tone" and provide context for
   * users that aren't able to discern intent from colour alone. For continuity
   * within our products we recommend that this is rarely used.
   */
  icon?: ComponentType<any>;
};

export const Notice = ({
  actions,
  children,
  size = 'medium',
  tone = 'informative',
  title,
  icon,
}: NoticeProps) => {
  const theme = useChakraTheme();

  const Icon = icon || toneToIcon[tone];
  const padding = sizeToPadding[size];

  return (
    <Flex
      role='alert'
      aria-live='polite'
      // styles
      alignItems='flex-start'
      background={toneToBackgroundColor[tone]}
      borderWidth='thin'
      borderColor={toneToBorderColor[tone]}
      borderRadius={size === 'small' ? 'base' : 'md'}
      gap='2'
      paddingX={padding.x}
      paddingY={padding.y}
    >
      <Icon
        size={size === 'small' ? 18 : 20}
        color={theme[toneToIconColor[tone]]}
      />

      {/*
       * NOTE: `alignSelf="center"` is a little trick to center-align single
       * lines of text, while allowing text that wraps to be top-aligned,
       * relative to the icon.
       */}
      <Stack gap='2' flex={1} alignSelf='center'>
        {title && (
          <Text fontSize='md' fontWeight='extrabold'>
            {title}
          </Text>
        )}

        {children ? <Text fontSize='sm'>{children}</Text> : null}

        {actions && (
          <Flex gap='2'>
            {actions.primary && (
              <Button
                size='sm'
                onClick={actions.primary.action}
                colorScheme={
                  actions.primary.tone
                    ? toneToColorScheme[actions.primary.tone]
                    : undefined
                }
                isLoading={actions.primary.loading}
              >
                {actions.primary.label}
              </Button>
            )}
            {actions.secondary && (
              <Button
                size='sm'
                onClick={actions.secondary.action}
                colorScheme={
                  actions.secondary.tone
                    ? toneToColorScheme[actions.secondary.tone]
                    : undefined
                }
                isLoading={actions.secondary.loading}
              >
                {actions.secondary.label}
              </Button>
            )}
          </Flex>
        )}
      </Stack>
    </Flex>
  );
};

// Maps
// ------------------------------

const toneToIcon = {
  informative: Info,
  critical: AlertOctagon,
  positive: CheckCircle,
  cautious: AlertTriangle,
};

const toneToColorScheme = {
  critical: 'red',
  positive: 'green',
};

const toneToBackgroundColor = {
  informative: 'blue.100',
  critical: 'red.100',
  positive: 'green.100',
  cautious: 'orange.100',
};

const toneToBorderColor = {
  informative: 'blue.300',
  critical: 'red.300',
  positive: 'green.300',
  cautious: 'orange.300',
};

const toneToIconColor = {
  informative: 'colors.blue.500',
  critical: 'colors.red.500',
  positive: 'colors.green.500',
  cautious: 'colors.orange.500',
} as const;

const sizeToPadding = {
  small: { x: '2', y: '2' },
  medium: { x: '4', y: '4' },
} as const;

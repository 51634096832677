import { Notice } from '~/design-system/notice';
import type { JsonValue } from '~/shared-types/jsonValue';
import type { PublicAPIResponse } from '~/public-api/common/types';

export const APIResponseErrorNotice = ({
  title,
  fetcherData,
}: {
  title?: string;
  fetcherData: Awaited<PublicAPIResponse<JsonValue>> | undefined;
}) => {
  if (fetcherData?.ok !== true && fetcherData?.ok !== false) {
    return null;
  }
  if (fetcherData.ok) {
    return null;
  }

  return (
    <Notice title={title} tone='critical'>
      {fetcherData.statusText ||
        `Somethign went wrong, we couldn't complete your request.`}
    </Notice>
  );
};
